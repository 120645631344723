import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { setStatusBarMessage, setTheme, setTitle } from "./app/actions";
import { returnAPIURL, getTicketIDFromURL, Justification } from "./Constants"
import { Header, 
        SpaceBetween, 
        ContentLayout
} from "@amzn/awsui-components-react/polaris";
import SubIssueTable from './SubIssueTable';
import IssuePropertiesPanel from './IssuePropertiesPanel';
import Dropdown from './Dropdown';
import StatusBar from "./StatusBar";
import "./styles/portrait.css"
import "./styles/FormPage.css"
import axios from "axios";
import { getJwtToken } from './Auth';
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import AnnotationContext from "@amzn/awsui-components-react/polaris/annotation-context";
import Hotspot from "@amzn/awsui-components-react/polaris/hotspot";

/**
 * Displays a nice circular portrait and user id
 *
 * @param {string} name
 * @public
 */
function UserProfile(props) {
  if(props.user) {
    return(
      <SpaceBetween direction="horizontal" size="xs">
            <div><a href={`https://phonetool.amazon.com/users/${props.user}`} target='_blank' rel='noopener noreferrer'><img className="circular-portrait small" src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${props.user}`} alt='Phonetool'/></a></div>
            <div style={{'padding': '10px 0'}}><b>{props.user}</b></div>
      </SpaceBetween>
    );
  }
  
  //No user, return empty indicator
  return <b>--</b>;
}

export default function FormPage(params){
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.currentViews.loggedInUser); //Get logged in user state
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(['issuevault']); //Set up cookie store
  const apiURL = returnAPIURL()

  const [loading, setLoading] = React.useState(true); 
  const [loadingHistory, setLoadingHistory] = React.useState(true); 
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [issueData, setIssueData] = React.useState({});
  const [openSubIssues, setOpenSubIssues] = React.useState([]);
  const [fixedSubIssues, setFixedSubIssues] = React.useState([]);
  const [subIssueSummaryMapping, setSubIssueSummaryMapping] = React.useState({}); //Used to map sid -> summary for history table as we have two lists with subIssue data. This is just a little more efficient.
  const [justificationOptions, setJustificationOptions] = React.useState([]);
  const [errorState, setErrorState] = React.useState(null);
  const [missingUUID, setMissingUUID] = React.useState(false);
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [ticket_id, setTicket_id] = React.useState(getTicketIDFromURL(window.location.pathname));
  const [prevSaveSubIssues, setPrevSaveSubIssues] = React.useState([]);
  const [numUnsavedSubIssues, setNumUnsavedSubIssues] = React.useState(0);
  const [subIssueDifferences, setSubIssueDifferences] = React.useState([]);
  const [tutorialVisible, setTutorialVisible] = React.useState(false);

  const [historyData, setHistoryData] = React.useState([]);
  const [historyErrorState, setHistoryErrorState] = React.useState(null);

  //Sets theme (dark/light mode) when DOM finishes loading based on theme cookie
  React.useEffect(() => { 
    if('theme' in cookies && cookies.theme) {
      dispatch(setTheme(cookies.theme));
    }
    // eslint-disable-next-line
  }, []);

  //Load issue data
  React.useEffect(() => { 
    loadIssueData();
    loadHistoryData();
    // eslint-disable-next-line
  }, [apiURL]);

  //Load issue data
  // React.useEffect(() => { 
  //   loadHistoryData();
  //   // eslint-disable-next-line
  // }, [apiURL]);

  //Window event listener for page reload, close, or navigate
  React.useEffect(() => {
      const unloadCallback = (event) => {
          event.preventDefault();
          event.returnValue = ""; //Needed for Chrome
          return "";
      };
      
      if(unsavedChanges) {
        window.addEventListener("beforeunload", unloadCallback); //Unsaved changes! Present confirm dialog
        return () => {
            window.addEventListener("popstate", () => {}); //User navigates away from page
            window.removeEventListener("beforeunload", unloadCallback); //User closes window
        }
      }
      else {
        return () => {}; //Default, no unsaved changes
      }
  }, [unsavedChanges]);

  const loadIssueData = async() => {
    dispatch(setStatusBarMessage(null));
    setErrorState(null);
    setLoading(true);

    let ticket_id = getTicketIDFromURL(window.location.pathname); // Get id for issue from url
    if(ticket_id === null) {
      if(cookies.ticket_id) { //Check if we have a local cookie stored with the ticket ID instead
        ticket_id = cookies.ticket_id; //Set issue ID to value stored in cookie and remove cookie

        // re-reoute to proper url on refresh after auth redirect
        let newURL = "ticket/" + ticket_id

        window.location.assign(newURL);
      }
    }
    if(cookies.ticket_id) { //Remove cookie, if it exists
      removeCookie('ticket_id', {path: '/'});
    }

    setTicket_id(ticket_id)

    if(ticket_id) {
      // Get auth for axios
      const options = {
        headers: {
          'Authorization': `Bearer ${getJwtToken()}`,
          'Content-Type': 'application/json'
        },
      };

      let sub_issues;
      try {
        // Get request to get issue contents
        let response = await axios.get(`${apiURL}/ticket?id=${ticket_id}`, options);
        let data = {};

        if(response['data']){
          data = response['data'];
          let fixedSubIssues = [];
          let openSubIssues = [];
          let subIssueSummaryMapping = {};

          // Get list of sub issues to generate list of options for multiselect
          if(data.sub_issues) {
            if(data.sub_issues.length > 0) { //Defined sub-issues when filed through Issue API. We can sort existing field
              sub_issues = data.sub_issues;
              sub_issues.sort((a, b) => a.sid.localeCompare(b.sid));
              
              for(const sub_issue of sub_issues) { //Separate subissues into fixed and open based on fix_confirmed flag
                subIssueSummaryMapping[sub_issue['sid']] = sub_issue['summary'];

                if(sub_issue.fix_confirmed) {
                  fixedSubIssues.push(sub_issue);
                }
                else {
                  openSubIssues.push(sub_issue);
                }
              }

              setOpenSubIssues(openSubIssues);
              setFixedSubIssues(fixedSubIssues);
              setPrevSaveSubIssues(openSubIssues);
              setSubIssueSummaryMapping(subIssueSummaryMapping);
            }
            else { //No defined sub-issues, meaning none were defined at filing time. We can parse the body and create our own "fake" sub-issue
              let fakeSubIssue = [
                {
                  "id": data.ticket_id,
                  "summary": data.summary ? data.summary : '--', //Look for summary field
                  "justification": null, //The first time a fake sub-issue is created, justification and comment are null. After saving, we populate sub-issues in the original ticket and save values.
                  "comment": null
                }
              ];
              let subIssueSummaryMapping = {};
              subIssueSummaryMapping[fakeSubIssue[0]['id']] = fakeSubIssue['summary'];

              setOpenSubIssues(fakeSubIssue);
              setPrevSaveSubIssues(fakeSubIssue);
              setSubIssueSummaryMapping(subIssueSummaryMapping);
            }
          }
          setIssueData(data);
          dispatch(setTitle(`Issue Companion - ${data.ticket_id}`)); //Set page title to include ticket ID

          // Sort justifcaitons
          var justOptions = {};
          let justArray = data.justifications;
          justArray.sort();

          justOptions = justArray.map((justification) => ({
            label: justification,
            value: justification,
          }));

          setJustificationOptions(justOptions);  
        }
      } catch (e) {
        setErrorState(e);
      }
    }
    else { //No ticket ID
      setMissingUUID(true);
    }

    setLoading(false);
  }

  const loadHistoryData = async() => {
    setHistoryErrorState(null)
    setLoadingHistory(true);

    try {
      // Get auth for axios
      const options = {
        headers: {
          'Authorization': `Bearer ${getJwtToken()}`,
          'Content-Type': 'application/json'
        },
      };

      //Get request to for issue history
      let history_response = await axios.get(`${apiURL}/history?ticket=${ticket_id}`, options);
      let history_data = [];
      if(history_response['data'] && history_response['data']['history']){
        for(const issue_id of Object.keys(history_response['data']['history'])) { //Iterate over each issue and parse its history
          for(var history_entry of history_response['data']['history'][issue_id]) { //Parse list of changes
            history_entry['id'] = issue_id; //Save ID with corresponding history object
            history_entry['summary'] = subIssueSummaryMapping[issue_id]; //Get summary from mapping as it is not included in history response. Table will handle display if undefined or empty.
            history_data.push(history_entry);
          }
        }
      }
      //Sort by date descending
      history_data.sort((a, b) => {
        //Sort by date updated
        if(a['date_updated'] < b['date_updated']) {
          return 1;
        }
        else if(a['date_updated'] > b['date_updated']) {
          return -1;
        }
        //If date updated is the same, sort by issue id
        return a['id'].localeCompare(a['id']);
      });
      setHistoryData(history_data);
    } catch (e) {
      setHistoryErrorState(e);
    }

    setLoadingHistory(false);
  }

  //Handles saving changes using backend API
  const submitChanges = async() => {
    dispatch(setStatusBarMessage(null));
    setSubmitLoading(true);

    try {
      // Get auth for axios
      // eslint-disable-next-line
      const options = {
        headers: {
          'Authorization': `Bearer ${getJwtToken()}`,
          'Content-Type': 'application/json'
        },
      };

      let submit_payload = {
        "ticket_uuid": ticket_id,
        "sub_issues": subIssueDifferences
      }

      await axios
      .post(apiURL + "/submit", submit_payload, options)
      .then((response) => {
        dispatch(setStatusBarMessage({type: 'success', message: 'Changes saved.'})); //Display success message
      })
      .catch((error) => {
        dispatch(setStatusBarMessage({type: 'error', message: 'An error has occured. The changes could not be saved.'})); //Display success message
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log(error);
        }
      });

      setUnsavedChanges(false); //Changes saved. Set unsaved changes field to false
      setNumUnsavedSubIssues(0);
      setPrevSaveSubIssues(openSubIssues);
      setSubIssueDifferences({});
    }
    catch(err) {
      dispatch(setStatusBarMessage({type: 'error', message: 'Failed to save changes: ' + err})); //Display failure message
    }

    setSubmitLoading(false);
  }

  //Callback for subissue table to set subissues list. Updates the main subIssue state
  const setUpdatedSubIssues = (updatedSubIssues) => {
    setOpenSubIssues(updatedSubIssues); //Set back state
    let keys = Object.keys(prevSaveSubIssues);
    let numberOfDifferences = 0;
    let tempDifferences = [];

    // Find differences between updated and old subissues
    for(let i = 0; i < keys.length; i++){
      if ((keys[i] in updatedSubIssues) && (JSON.stringify(updatedSubIssues[keys[i]]) !== JSON.stringify(prevSaveSubIssues[keys[i]]))) {
        numberOfDifferences++;
        tempDifferences.push(updatedSubIssues[keys[i]]);
      }
    }

    setNumUnsavedSubIssues(numberOfDifferences);
    setSubIssueDifferences(tempDifferences);
    //Set state to indicate changes were made. If user tries to navigate away from page without saving, display a popup
    setUnsavedChanges(true);
  }

  //Callback for subissue table to undo changes to subissues.
  const undoIssueChanges = () => {
    setOpenSubIssues(prevSaveSubIssues); //Set back state
    let numberOfDifferences = 0;
    setNumUnsavedSubIssues(numberOfDifferences);

    //Set state to indicate changes were made. If user tries to navigate away from page without saving, display a popup
    setUnsavedChanges(false);
  }

  //Callback to set tutorial visibility
  const setTutorialVisibleCallback = (visible) => {
    setTutorialVisible(visible);
  }

  // If missing UUID
  if(missingUUID) {
    dispatch(setStatusBarMessage({type: 'error', message: 'No issue ID provided.'}));
  }

  //Handle other error state. Exception message is stored in error state
  if(errorState != null) {
    dispatch(setStatusBarMessage({type: 'error', message: 'Could not load issue details. Please check issue ID or permissions: ' + errorState}));
  }

  if(historyErrorState != null){
    dispatch(setStatusBarMessage({type: 'error', message: 'Could not load history details. Please check issue ID or permissions: ' + historyErrorState}));
  }

  //Set up annotation context tutorial for managing an issue. Visibility toggled through dropdown
  let issueVaultAnnotationContextTutorial = {
    tasks: [
      {
        title: "Manage an Issue",
        steps: [
          {
            title: "View ticket details",
            content: (
              <p>
                Use the properties panel to quickly review issue content or request help from DSTS.
              </p>
            ),
            hotspotId: "issue_properties_hotspot"
          },
          {
            title: "View fixed issues",
            content: (
              <p>
                The fixed issues table shows issues that DSTS automation has no longer detected and confirmed as fixed.
              </p>
            ),
            hotspotId: "fixed_table_hotspot"
          },
          {
            title: "View open issues",
            content: (
              <div>
                <p>The open issues table shows issues that need to be addressed.</p>
                <p>To update an issue, set the Status and Comment fields directly in the table or select multiple issues to use the bulk issue editor.</p>
              </div>
            ),
            hotspotId: "open_table_hotspot"
          }
        ]
      }
    ]
  };

  return (
    //Parent container
    <ContentLayout
      header={
        <SpaceBetween size="xs">
          <Header
            variant="h1"
            actions={
              <div className="headerDiv">
                <SpaceBetween direction="horizontal" size="s">
                  <UserProfile user={loggedInUser} />
                  <Dropdown 
                    setTutorialVisibleCallback={setTutorialVisibleCallback}
                  />
                </SpaceBetween>
                </div>
            }
            >
              <div className="headerDiv">Issue Companion</div> 
          </Header>
          <StatusBar/>
        </SpaceBetween>
      }
    >
      <Container 
        fitHeight    
      >
        <AnnotationContext
          currentTutorial = {tutorialVisible ? issueVaultAnnotationContextTutorial : null}
          i18nStrings={{
            stepCounterText: (stepIndex, totalStepCount) =>
              (stepIndex + 1) + "/" + totalStepCount,
            taskTitle: (taskIndex, taskTitle) =>
              taskTitle,
            labelHotspot: (
              openState, stepIndex, totalStepCount
            ) => (stepIndex + 1) + "/" + totalStepCount,
            nextButtonText: "Next",
            previousButtonText: "Previous",
            finishButtonText: "Finish",
            labelDismissAnnotation: "Close"
          }}
          onFinish={() => {setTutorialVisibleCallback(false);}}
        >
          <ColumnLayout columns={2}>
            <IssuePropertiesPanel 
              issue={issueData} 
              history={historyData ? historyData : []}
              subIssueSummaryMapping={subIssueSummaryMapping}
              loading={loading}
              ticket_id={ticket_id}
              user={loggedInUser}
            />
            <SpaceBetween size="m" direction="vertical">
              <ExpandableSection 
                headerText={
                  <SpaceBetween size="xxs" direction="horizontal">
                    { `Fixed Issues (${fixedSubIssues.length})`}
                    <Hotspot hotspotId="fixed_table_hotspot" direction="right"/>
                  </SpaceBetween>
                }
              >
                <SubIssueTable 
                  subIssues={fixedSubIssues ? fixedSubIssues : []}
                  justifications={justificationOptions && justificationOptions.length > 0 ? justificationOptions : Justification} 
                  loading={loading}
                  loadingHistory={loadingHistory}
                  editable={false}
                />
              </ExpandableSection>
              <ExpandableSection 
                defaultExpanded
                headerText={
                  <SpaceBetween size="xxs" direction="horizontal">
                    {`Open Issues (${openSubIssues.length})`}
                    <Hotspot hotspotId="open_table_hotspot" direction="right"/>
                  </SpaceBetween>
                }
              >
                <SubIssueTable 
                  subIssues={openSubIssues ? openSubIssues : []}
                  justifications={justificationOptions && justificationOptions.length > 0 ? justificationOptions : Justification} 
                  loading={loading}
                  editable={true}
                  submitLoading={submitLoading}
                  setUpdatedSubIssuesCallback={setUpdatedSubIssues}
                  undoIssueChangesCallback={undoIssueChanges}
                  submitCallback={submitChanges}
                  unsavedChanges={unsavedChanges}
                  numUnsavedSubIssues={numUnsavedSubIssues}
                />
              </ExpandableSection>
            </SpaceBetween>
          </ColumnLayout>
        </AnnotationContext>
      </Container>
    </ContentLayout>
  );
}