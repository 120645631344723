import { getCurEnv } from "./app/envVarsReader";

//Default justifications in the case we couldn't load an updated list from frontend API
export const Justification = [
    {label: 'False Positive', value: 'False Positive'},
    {label: 'External AOSP', value: 'External AOSP'},
    {label: 'External OSS/Vendor', value: 'External OSS/Vendor'},
    {label: 'Not Current Code', value: 'Not Current Code'},
    {label: 'Deprecated Code', value: 'Deprecated Code'},
    {label: 'Internal, Test, or Unused Code', value: 'Internal, Test, or Unused Code'},
    {label: 'Already Fixed', value: 'Already Fixed'},
    {label: 'Duplicate', value: 'Duplicate'}
]; 

//Cognito config for test environment
const testAuthConfig = {
    AppWebDomain: 'security-automation-issue-vault-test.auth.us-west-1.amazoncognito.com',
    ClientId: '7k8o98068s1n5ah1qt67fkt1pi',
    RedirectUriSignIn: window.location.origin,
    RedirectUriSignOut: window.location.origin,
    TokenScopesArray: ['openid', 'profile'],
    UserPoolId: 'us-west-1_E4lz5EOGc'
}

//Cognito config for prod environment
const prodAuthConfig = {
    AppWebDomain: 'security-automation-issue-vault-prod.auth.us-west-2.amazoncognito.com',
    ClientId: '1t88km52lcjqqarjqit3fm08j9',
    RedirectUriSignIn: window.location.origin,
    RedirectUriSignOut: window.location.origin,
    TokenScopesArray: ['openid', 'profile'],
    UserPoolId: 'us-west-2_okea84ICq'
}

//Mapping of environment name to auth config
const configAuthDir = {
    "test": testAuthConfig,
    "prod": prodAuthConfig,
}

//Returns Cognito config for current environment
export function getAuthConfig() {
    return configAuthDir[getCurEnv()];
}

//Mapping of environment name to api url
const configAPIURL = {
    "test": "https://api.test.issue-companion.digital-security.a2z.com",
    "prod": "https://api.issue-companion.digital-security.a2z.com",
    "local": "http://api.localhost"
}

//Returns the API url for the current environment given the mapping above
export function returnAPIURL() {
    return configAPIURL[getCurEnv()];
}

//Returns the Issue Vault ticket UUID from the end of the URL
export function getTicketIDFromURL(url) {
    if(url.includes('/')) {
        let urlSplit = url.split("/");
        if(urlSplit.length === 3 && urlSplit[2] !== '') { //<BASE_URL>/ticket/<ID>
            return urlSplit[2];
        }
    }
    return null;
}