import { CognitoAuth } from "amazon-cognito-auth-js";
import { getAuthConfig } from "./Constants";
import { setLoggedInUser } from "./app/actions";
import { getCurEnv } from "./app/envVarsReader";

let JWTToken;
let username;
let userGroups = [];

//Cognito user authentication and authorization
export const auth = async (dispatch) => {
    let decodedAuthObj;
    if(getCurEnv() === 'local') { //Handle local testing by assigning dummy test user
        decodedAuthObj = {
            identities: [{ userId: "test" }]
        };
        JWTToken = "local";
        username = "test";
    }
    else {
        let authObj;
        try {
            authObj = await generateOrGetAuthObj();
        } catch (e) {
            window.location.href = "/unauth";
            return;
        }

        decodedAuthObj = authObj.decodePayload(); //Parse response and get JWT token
        if(decodedAuthObj) {
            if(decodedAuthObj['custom:unique_name']) { //Custom claim for LASSO unique name
                username = decodedAuthObj['custom:unique_name'];
            }
            if(decodedAuthObj['custom:group']) { //Custom claim for LASSO groups
                try {
                    userGroups = JSON.parse(decodedAuthObj['custom:group']);
                }
                catch(err) {} //Defaults to empty string for group
            }
        }
        JWTToken = authObj.getJwtToken();
    }

    const identities = decodedAuthObj.identities;
    if (!identities && identities.length !== 1) {
        return;
    }

    if(!username) {
        return;
    }

    dispatch(setLoggedInUser(username)); //Update state with auth user's id
}

export const getJwtToken = () => {
    return JWTToken;
}

export const getUserGroups = () => {
    return userGroups;
}

function removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string and the grant code is not reusable
    if (window.history.length > 0) {
        const newHref = window.location.href.split('?code')[0];
        window.history.replaceState(undefined, 'IssueVault', newHref);
    }
}

const generateOrGetAuthObj = () => {
    return new Promise((resolve, reject) => {
        const auth = new CognitoAuth(getAuthConfig()) //Get Cognito config and perform auth

        auth.userhandler = {
            onFailure: (err) => {
                removeQueryFromLocation();
                reject(err);
            },
            onSuccess: (result) => {
                removeQueryFromLocation();
                resolve(auth.getSignInUserSession().getIdToken());
            }
        };

        auth.useCodeGrantFlow();

        const href = window.location.href;
        const session = auth.getSignInUserSession();

        if (session.isValid()) {
            resolve(session.getIdToken());
        } else if (href.indexOf('?code') > 0) {
            auth.parseCognitoWebResponse(href);     // triggers userhandler
        } else {
            auth.clearCachedTokensScopes();
            auth.getSession();
        }
    });
}