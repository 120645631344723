import Link from "@amzn/awsui-components-react/polaris/link";

/**
 * Exported view for displaying text with a link to an external resource
 *
 * @param {text} display text
 * @param {link} link ref  
 * @public
 */
export default function ExternalLink(props) {
    if(props.text && props.link && props.link.length > 0) {
        return(
            <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href={props.link}
                >
                {props.text ? props.text : '--'}
            </Link>
        );
    }
    else if(props.text) { //If there's no link, we should at least display the text
        return props.text ? props.text : '--';
    }

    return '--';
}