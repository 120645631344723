import * as React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import ExternalLink from "./ExternalLink";

/**
 * Modal for contact info of admins
 *
 * @param {visible} visible Control visibility of modal
 * @param {setContactVisibility} setContactVisibility Control visibility of modal
 * @public
 */
export default function DropdownModalFeedback(props) {

    let status = props.visible

    const handleDismiss = (e) => {
        e.preventDefault();
        props.setFeedbackVisibility(false)
    }

    return (
    <Modal
        onDismiss={handleDismiss}
        visible={status}
        closeAriaLabel="Close modal"
        footer={
            <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={handleDismiss}>Close</Button>
            </SpaceBetween>
            </Box>
        }
      header="Provide Feedback or Contact Us"
    >
        <div>If you have any feedback on the site, cut us a ticket here: <ExternalLink text="Provide Feedback" link="https://tiny.amazon.com/1d61wpnhy"/></div>
        <div>If would like to contact the site admins, cut us a ticket here: <ExternalLink text="Contact Us" link="https://tiny.amazon.com/v3iy6lor"/></div>
    </Modal>
  );
}