import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Button from "@amzn/awsui-components-react/polaris/button";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from "@amzn/awsui-components-react/polaris/select";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import { SpaceBetween, Alert, Box } from "@amzn/awsui-components-react";
import BulkEditor from "./BulkEditor";
import { useCollection } from '@amzn/awsui-collection-hooks';

const ID_COLUMN_MIN_WIDTH = 100; //Minimum width of ID column before text is wrapped to the next line
const SUMMARY_COLUMN_MIN_WIDTH = 275; //Minimum width of summary column before text is wrapped to the next line
const STATUS_COLUMN_MIN_WIDTH = 275; //Minimum width of status column before text is wrapped to the next line
const COMMENTS_COLUMN_MIN_WIDTH = 250; //Minimum width of comments column before text is wrapped to the next line

/**
 * Button for saving sub-issue changes
 *
 * @param {loading} whether save is currently in progress
 * @param {callback} function callback for saving subissue state in backend
 * @protected
 */
function SaveButton (props) {
   if(props.loading) {
        return <Spinner />;
   }

   const handleSaveAction = (e) => {
        e.preventDefault();
        props.callback();
    }

    if(!props.enabled) {
        return <Button disabled variant="primary">Save</Button>
    }

   return <Button variant="primary" onClick={handleSaveAction}>Save</Button>
};

function createLabelFunction(columnName) {
    return ({ sorted, descending }) => {
      const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
      return `${columnName}, ${sortState}.`;
    };
}

/**
 * Exported sub-issues table with editable columns and multi-select
 *
 * @param {subIssues} list of sub-issues
 * @param {justifications} list of justification status values, either from constants file or frontend API response
 * @param {setUpdatedSubIssuesCallback} function to update sub-issue state in main FormPage
 * @param {undoIssueChangesCallback} function
 * @param {editable} boolean to toggle whether items in this table are selectable and editable
 * @public
 */
export default function SubIssueTable(props) {
    const [selectedIssues, setSelectedIssues] = React.useState([]); //User selected issues in table
    const [filteredIssues, setFilteredIssues] = React.useState([]); //User filitered issues with search box in table

    const columnEditConfig = { //Edit config. Not active when editable is set to false
        'justification': {
            ariaLabel: 'Status',
            editIconAriaLabel: "editable",
            errorIconAriaLabel: "Status Error",
            editingCell: (
                item,
                { currentValue, setValue }
            ) => {
                return(<Select
                    autoFocus={false}
                    expandToViewport={true}
                    selectedOption={{label: currentValue, value: currentValue}}
                    options={props.justifications}
                    onChange={event => {
                        setValue(
                            event.detail.selectedOption.value ?? item.justification
                        )
                    }}
                />);
            }
        },
        'comment': {
            ariaLabel: 'Status',
            editIconAriaLabel: "editable",
            errorIconAriaLabel: "Status Error",
            editingCell: (
                item,
                { currentValue, setValue }
            ) => {
                return(
                    <Input
                        autoFocus={false}
                        value={currentValue ?? item.comment}
                        onChange={event => 
                            setValue(event.detail.value)
                        }
                    />
                );
            }
        }
    }

    const columnDefinitions = [
        {
            id: "id",
            header: "ID",
            cell: (item) => item.sid,
            ariaLabel: createLabelFunction('id'),
            sortingField: 'id',
            minWidth: ID_COLUMN_MIN_WIDTH
        },
        /* WIP: fix override display for admin users */
        // {
        //     id: "fix_override",
        //     header: "Fix Override",
        //     cell: (item) => item.id,
        //     ariaLabel: "fix_override",
        //     editIconAriaLabel: "editable",
        //     errorIconAriaLabel: "Status Error",
        //     editingCell: (
        //         item,
        //         { currentValue, setValue }
        //     ) => {
        //         return (
        //             <Checkbox
        //                 onChange={({ detail }) =>
        //                     setValue(detail.checked)
        //                 }
        //                 checked={currentValue}
        //             >
        //               Fix Override
        //             </Checkbox>
        //           );
        //     }
        // },
        {
            id: "summary",
            header: "Summary",
            cell: (item) => {
                return item.summary;
            },
            ariaLabel: createLabelFunction('summary'),
            sortingField: 'summary',
            minWidth: SUMMARY_COLUMN_MIN_WIDTH
        },
        {
            id: "justification",
            header: "Status",
            cell: (item) => {
                if (item.fix_confirmed){
                    return <Box variant="p">Fix Confirmed <Icon name="status-positive" variant="success"/></Box>
                }
                return item.justification;
            },
            minWidth: STATUS_COLUMN_MIN_WIDTH,
            editConfig: props.editable ? columnEditConfig['justification'] : null
        },
        {
            id: "comment",
            header: "Comments",
            cell: (item) => {
                return item.comment;
            },
            minWidth: COMMENTS_COLUMN_MIN_WIDTH,
            editConfig: props.editable ? columnEditConfig['comment'] : null
        }
    ];

    const [filteringText, setFilteringText] = React.useState(""); //User-entered search text
    const collectionPreferences = { //Column layout and table prefs
        visibleContent: [
            "id",
            "summary",
            "justification",
            "comment"
        ],
        wrapLines: true,
        custom: "table"
    };

    // console.log(props.subIssues)

    //Populate initial list of issue without the search filter
    React.useEffect(() => {
        setFilteredIssues(props.subIssues);
    }, [props.subIssues])

    //Clears selected issues in table. Used in Bulk Editor cancel callback
    const clearSelection = () => {
        setSelectedIssues([]);
    }

    //Sets values for selected issues. Used in Bulk Editor cancel callback
    const bulkSetCallback = (bulkStatus, bulkComments) => {
        let updatedSubIssues = props.subIssues;
        for(const selectedItem of selectedIssues) {
            const updatedItem = { ...selectedItem,
                justification: bulkStatus,
                comment: bulkComments
            }
            updatedSubIssues = updatedSubIssues.map(item => (item === selectedItem ? updatedItem : item))
        }
        props.setUpdatedSubIssuesCallback(updatedSubIssues);
        clearSelection();
    }

    //Sets values for selected issues. Used in Bulk Editor cancel callback
    const clearJustificationsAndComments = () => {
        let updatedSubIssues = props.subIssues;
        for(const selectedItem of selectedIssues) {
            const updatedItem = { ...selectedItem,
                justification: null,
                comment: null
            }
            updatedSubIssues = updatedSubIssues.map(item => (item === selectedItem ? updatedItem : item))
        }
        props.setUpdatedSubIssuesCallback(updatedSubIssues);
        clearSelection();
    }

    const { items, collectionProps } = useCollection(
        filteredIssues,        // exact vuln only
        {
            sorting: {},
        }
    );

    const undoChanges = () => {
        props.undoIssueChangesCallback()
        clearSelection();
    }

    let undoChangesButton
    if (props.unsavedChanges) {
        undoChangesButton = <Button onClick={undoChanges}>Undo</Button>
    }

    return (
        <SpaceBetween size="l">
            <Table
                items={items}
                columnDefinitions={columnDefinitions}
                isItemDisabled={item => item.fix_confirmed}
                visibleColumns={collectionPreferences.visibleContent}
                contentDensity="compact"
                trackBy="sid"
                selectionType={"multi"}
                wrapLines={true}
                loading={props.loading}
                loadingText={"Loading Sub-Issues"}
                selectedItems={selectedIssues}
                onSelectionChange={({detail}) => setSelectedIssues(detail.selectedItems)}
                {...collectionProps}
                header={
                    <div>                    
                        {
                            props.numUnsavedSubIssues > 0 && 
                                <Alert
                                statusIconAriaLabel="Info"
                                header="Save Changes"
                                >
                                    <SpaceBetween direction="vertical" size="xxxs">
                                        <p>{props.numUnsavedSubIssues} {props.numUnsavedSubIssues === 1 ? "issue has" : "issues have"} unsaved changes.</p>
                                        <SpaceBetween direction="horizontal" size="xxs">
                                            <SaveButton loading={props.submitLoading} callback={props.submitCallback} enabled={props.unsavedChanges}/>
                                            {undoChangesButton}
                                        </SpaceBetween>
                                    </SpaceBetween>
                                </Alert>
                        }
                    </div>
                }
                filter={
                    <TextFilter
                        filteringPlaceholder="Search"
                        filteringText={filteringText}
                        onChange={({ detail }) => {
                                let filter = detail.filteringText.toLowerCase().trim();

                                if(!filter === null || filter.length === 0) {
                                    setFilteredIssues(props.subIssues);
                                }
                                else {
                                    let tmp = [];
                                    for(const issue of props.subIssues) {
                                        //Filter issues on matching id, summary, status, or comment (compared lowercase)
                                        let summary = issue.summary ? issue.summary.toLowerCase() : '';
                                        let id = issue.sid ? issue.sid.toLowerCase() : '';
                                        let status = issue.justification ? issue.justification.toLowerCase() : '';
                                        let comment = issue.comment ? issue.comment.toLowerCase() : '';

                                        if(id.includes(filter)
                                            || summary.includes(filter)
                                            || status.includes(filter)
                                            || comment.includes(filter)) {
                                            tmp.push(issue);
                                        }
                                    }
                                    setFilteredIssues(tmp);
                                }
                                
                                setFilteringText(detail.filteringText);
                            }
                        }
                    />
                }
                submitEdit={async (currentItem, column, value) => {        
                    await new Promise(resolve => setTimeout(resolve, 250));
                    let currentSubIssues = props.subIssues;
                    const newItem = { ...currentItem,
                        [column.id]: value,
                    };
                    props.setUpdatedSubIssuesCallback(currentSubIssues.map(item => (item === currentItem ? newItem : item)));
                }}
                empty={
                    <div className="awsui-util-t-c">
                        <div className="awsui-util-pt-s awsui-util-mb-xs">
                            <b>No Issues</b>
                        </div>
                    </div>	
                }
                
            />
            <BulkEditor 
                selectedIssues={selectedIssues}
                issueCount={props.subIssues ? props.subIssues.length : 0}
                justifications={props.justifications ? props.justifications : []} 
                cancelCallback={clearSelection} 
                setCallback={bulkSetCallback}
                clearCallback={clearJustificationsAndComments}
            />
        </SpaceBetween>
    )
}