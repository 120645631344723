const LINK_PATTERN = /\[.+?\|((http|https|mailto)):.+?\]/g;
const TABLE_ROW_PATTERN = /\|/g;
const PANEL_START_PATTERN = /\{(panel:title=).+?\}/g;
const PANEL_END_PATTERN = /\{(panel)\}/g;
const CODE_PATTERN = /^[*\s]*\{(code.*)\}/g;
const CODE_PATTERN_INTERNAL = /\{(code.*)\}.+\{(code)\}/g;
const FIELD_KEY = /\*.+?\*:.+/g;
const BOLD_KEY = /\*.+?\*:*/g;
const SMALL_PATTERN = /~.+?~/g;

/**
 * Formats markdown link
 *
 * @param {line} Raw line from description
 * @param {matches} Array of regex matches on LINK_PATTERN
 * @returns Formatted HTML link
 * @protected
 */
function formatLink(line, matches) {
    for(const match of matches) {
        let text = match.substring(1);
        text = text.substring(0, text.indexOf("|"));
        let link = match.substring(match.indexOf("|")+1);
        link = link.substring(0, link.length - 1);
        let formattedLink = `<a href='${link}' target='_blank' rel='noopener noreferrer'>${text}</a>`;
        line = line.replace(match, formattedLink);
    }
    return line;
}

/**
 * Formats small text
 *
 * @param {line} Raw line from description
 * @param {matches} Array of regex matches on SMALL_PATTERN
 * @returns Formatted HTML small text
 * @protected
 */
function formatSmallText(line, matches) {
    for(const match of matches) {
        let text = '<small>' + match.substring(1, match.length - 1) + '</small>';
        line = line.replace(match, text);
    }
    return line;
}

/**
 * Formats markdown link
 *
 * @param {line} Raw line from description
 * @param {matches} Array of regex matches on TABLE_ROW_PATTERN
 * @param {firstRow} Whether we are parsing the first row of the table. Changes cell tags to <th></th>
 * @returns Formatted HTML table row
 * @protected
 */
function formatTableRow(line, matches, firstRow) {
    line = line.trim(); //remove leading and trailing whitespace
    line = line.replace(/\|\|/g, '|')
    let cell_tag_open = firstRow ? '<th class="description_th">' : '<td class="description_td">'; //Define opening tags based on firstRow
    let cell_tag_close = firstRow ? '</th>' : '</td>'; //Define closing tags based on firstRow
    line = `<tr>${cell_tag_open}${line.substring(1, line.length - 1)}${cell_tag_close}</tr>` //Set up start and end tags for row
    
    for(const match of matches) { 
        line = line.replace(match, `${cell_tag_close}${cell_tag_open}`); //Replace each '|' with cell end and start tags
    }
    return line;
}

/**
 * Formats panel start
 *
 * @param {line} Raw line from description
 * @param {matches} Array of regex matches on PANEL_START_PATTERN
 * @returns Formatted HTML panel representation
 * @protected
 */
function formatPanelStart(line, matches) {
    line = line.trim(); //remove leading and trailing whitespace
    let title = line.substring(line.indexOf("=")+1); //Get title
    if(title.indexOf('|') > 0) { //Check if there's additional params like borderStyle
        title = title.substring(0, title.indexOf('|'));
    }
    else { //No additional params, remove trailing bracket
        title = title.substring(0, title.length-1);
    }
    title = title.trim();
    
    return `<table class="description_table"><tr><td><h3 class="description_header">${title}</h3>`
}

/**
 * Formats field key
 *
 * @param {line} Raw line from description
 * @param {matches} Array of regex matches on FIELD_KEY
 * @returns Formatted HTML key field representation
 * @protected
 */
function formatKeyField(line, matches) {
    line = line.trim(); //remove leading and trailing whitespace
    let key = line.substring(0, line.indexOf(":")-1);
    key = key.replace(/\*/g, '')
    let value = line.substring(line.indexOf(":")+1);
    line = `<b>${key}</b>: ${value}` //Set up start and end bold tags
    return line;
}

/**
 * Formats bold text
 *
 * @param {line} Raw line from description
 * @param {matches} Array of regex matches on BOLD_KEY
 * @returns Formatted bold text representation
 * @protected
 */
function formatBoldText(line, matches) {
    line = line.trim(); //remove leading and trailing whitespace
    let key = line.replace(/\*/g, '')
    line = `<b><u>${key}</u></b>` //Set up start and end bold tags
    return line;
}

/**
 * Formats markdown in ticket decription as HTML
 *
 * @param {description} Raw ticket description
 * @returns Formatted HTML ticket description
 * @public
 */
export function formatDescription(description, formatDescription) {
    if(!formatDescription) { //Return description as-is with no formatting based on toggle
        return description;
    }

    let formatted = [];
    let tableFirstRowMatch = false;
    let insideCodeBlock = false;

    description.split('\n').forEach(function(line, index, array) {

        if(!line || line.replace(/\s/g, '').length < 1) {
            return; //continue
        }

        //If we're in a code block, do not format anything until we see the closing {code} tag
        if(line.match(CODE_PATTERN)) {
            if(!insideCodeBlock) {
                formatted.push('<br><table class="description_code_table"><tr><td>');
            }
            else {
                formatted.push('</td></tr></table>');
            }
            insideCodeBlock = !insideCodeBlock;
            return; //continue
        }

        if(insideCodeBlock) {
            formatted.push(line);
            return; //continue
        }

        //Remove '* ', as this is supposed to be a bulleted list
        line = line.replace(/\*\s/g, '');

        //Replace '----', as this is supposed to be a horizontal line
        line = line.replace(/----/g, '<hr>');

        //Parse small text next
        let match = line.match(SMALL_PATTERN);
        if(match) {
            line = formatSmallText(line, match);
        }
        
        //Parse key field next
        match = line.match(FIELD_KEY);
        if(match) {
            line = formatKeyField(line, match);
        }

        //Parse bold text next
        match = line.match(BOLD_KEY);
        if(match) {
            line = formatBoldText(line, match);
        }

        //Parse links first
        match = line.match(LINK_PATTERN);
        if(match) {
            line = formatLink(line, match);
        }

        //Parse panel start next
        match = line.match(PANEL_START_PATTERN)
        if(match) {
            line = formatPanelStart(line, match);
        }

        //Parse panel end next
        match = line.match(PANEL_END_PATTERN)
        if(match) {
            formatted.push('</td></tr></table>');
            return; //continue
        }

        //Parse internal code block
        match = line.match(CODE_PATTERN_INTERNAL)
        if(match) {
            let prefix = line.substring(0, line.indexOf('{code}'));
            formatted.push(prefix);
            formatted.push('<br><table class="description_code_table"><tr><td>');
            let suffix = line.substring(line.indexOf('{code}')+6);
            let codeContents = suffix.substring(0, suffix.indexOf('{code}'));
            formatted.push(codeContents)
            formatted.push('</td></tr></table>');
            suffix = suffix.substring(suffix.indexOf('{code}')+6);
            formatted.push(suffix);
            return; //continue
        }

        //Parse table elements next
        match = line.match(TABLE_ROW_PATTERN);
        if(match) {
            if(!tableFirstRowMatch) { //Add starting tag if this is the first table row encountered
                formatted.push('<br><table class="description_table">');
                tableFirstRowMatch = true;
                formatted.push(formatTableRow(line, match, true));
            }
            else {
                formatted.push(formatTableRow(line, match, false));
            }
            return; //continue
        }
        else { //After last table match, add closing tag
            if(tableFirstRowMatch) {
                formatted.push("</table>");
                tableFirstRowMatch = false;
            }
        }

        formatted.push(line);
    });

    return formatted.join("\n");
}