import * as React from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import HistoryModal from "./HistoryModal";

/**
 * @param {history} history
 * @param {loading} loading
 * @public
 */

export default function HistoryButton(props) {
    const [historyVisible, setHistoryVisible] = React.useState(false);

    const setHistoryVisibility = (visibility) => {
      setHistoryVisible(visibility);
    } 

    // if props is loading, show spinner
    if(props.loading) {
      return(
          <div align="center">
              <Spinner />
          </div>
      );
  }

  return (
      <div>
        <Button variant="primary" onClick={() => setHistoryVisible(true)}>View History</Button>
        <HistoryModal 
          visible={historyVisible} 
          setHistoryVisibility={setHistoryVisibility} 
          issue={props.issue}
          history={props.history} 
          subIssueSummaryMapping={props.subIssueSummaryMapping}
          loading={props.loading}
        />
      </div>
  );
}