import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { auth } from "./Auth";
import PuffLoader from "react-spinners/PuffLoader";
import FormPage from "./FormPage";
import { getTicketIDFromURL } from "./Constants"
import "@amzn/awsui-global-styles/polaris.css";
import "./styles/center.css";

export default function App() {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(['issuevault']); //Set up cookie store

  let ticket_id = getTicketIDFromURL(window.location.pathname);  // Get id for issue from url
  if(ticket_id !== null) {
    /* If there's an ID in the ticket URL, save it to a local cookie.
    * Later on, we'll check if this cookie exists. This handles the case where
    * Cognito auth is required and we lose the ticket ID in the URL after redirection.
    */
    setCookie('ticket_id', ticket_id, {path: '/', maxAge: 300}); //Expires in 5 minutes (300 seconds)
  }

  auth(dispatch); //Perform auth

  const userAlias = useSelector((state) => state.currentViews.loggedInUser);
  return userAlias ? //On successful auth, show form
    (
      < FormPage/>
    )
    : //No user? Show loading visual
    (
      <div class="centered">
        <PuffLoader color={"#0073bb"} loading={true} size={150} />
      </div>
    )
}
