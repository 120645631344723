import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";

export default function StatusBar(props) {
    //Status bar on app layout header
    const [items, setItems] = React.useState([]);
    const statusBarMessage = useSelector((state) => state.currentViews.statusBarMessage); //Receives status from redux store

    useEffect(() => {
        if(statusBarMessage) { //Set status bar type (success/error) and message
            setItems([
                {
                    type: statusBarMessage.type,
                    content: statusBarMessage.message,
                    dismissible: true,
                    dismissLabel: "Dismiss message",
                    onDismiss: () => setItems([]),
                    id: statusBarMessage.id ? statusBarMessage.id : 0
                  }
            ]);
          }
    }, [statusBarMessage])

    return <Flashbar items={items} />;
}