import * as React from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import Select from "@amzn/awsui-components-react/polaris/select";
import Container from "@amzn/awsui-components-react/polaris/container";
import Box from "@amzn/awsui-components-react/polaris/box";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import { SpaceBetween } from "@amzn/awsui-components-react";

/**
 * Key/Value pair display
 *
 * @param {label} key
 * @param {children} value
 * @protected
 */
const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div style={{whiteSpace: "pre-line"}}>{children}</div>
    </div>
);

/**
 * Exported bulk editor view for editing multiple issues' properties
 *
 * @param {selectedIssues} list of sub-issues
 * @param {issueCount} total number of sub-issues (selected + unselected)
 * @param {justifications} list of justification status values, either from constants file or frontend API response
 * @param {setCallback} function to update selected subissues in table
 * @param {cancelCallback} function to clear selection in table
 * @public
 */
export default function BulkEditor(props) {
    const [bulkStatus, setBulkStatus] = React.useState(null); //Bulk status selection
    const [bulkComments, setBulkComments] = React.useState(''); //Bulk comments

    //Hide view when no issues are available. If at least one issue is selected, we will show this component
    if(!props.selectedIssues || props.selectedIssues.length < 1) {
        return '';
    }

    //Clears form input
    const clearForm = () => {
        setBulkStatus(null);
        setBulkComments('');
    }

    //Clears form and calls parent component's cancel callback
    const cancelAction = () => {
        clearForm();
        props.cancelCallback();
    }

    //Calls parent component's save callback and clears form
    const setAction = () => {
        props.setCallback(bulkStatus ? bulkStatus.value : null, bulkComments ? bulkComments : '');
        clearForm();
    }

    let clearButton = <Button variant="primary" onClick={props.clearCallback}>Reset Values</Button>;

    return(
        <Container 
            header={
                <Header 
                    variant="h3"
                    actions={clearButton}
                >
                    {"Bulk Editor - " + props.selectedIssues.length + (props.selectedIssues.length === 1 ? " issue" : " issues")}
                    
                </Header>
            }
            footer={
                <div align="right">
                        <Button onClick={() => cancelAction()}>Cancel</Button>
                        &nbsp;
                        <Button variant="primary" onClick={() => setAction()}>Set</Button>
                </div>
            }
        >
            <SpaceBetween size="m">
                <ValueWithLabel label="Status">
                    <Select
                        autoFocus={false}
                        expandToViewport={true}
                        selectedOption={bulkStatus}
                        options={props.justifications}
                        onChange={event => {
                            setBulkStatus(event.detail.selectedOption);
                        }}
                    />
                </ValueWithLabel>
                <ValueWithLabel label="Comments">
                    <Textarea      
                        onChange={({ detail }) => setBulkComments(detail.value)}      
                        value={bulkComments}      
                    />
                </ValueWithLabel>
            </SpaceBetween>
        </Container>
    );
}