import * as React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";

const ID_COLUMN_MIN_WIDTH = 100; //Minimum width of ID column before text is wrapped to the next line
const SUMMARY_COLUMN_MIN_WIDTH = 275; //Minimum width of summary column before text is wrapped to the next line
const STATUS_COLUMN_MIN_WIDTH = 275; //Minimum width of status column before text is wrapped to the next line
const COMMENTS_COLUMN_MIN_WIDTH = 250; //Minimum width of comments column before text is wrapped to the next line
const FIX_CONFIRMED_COLUMN_MIN_WIDTH = 25; //Minimum width of fix confirmed column before text is wrapped to the next line
const UPDATED_BY_COLUMN_MIN_WIDTH = 100; //Minimum width of updated by column before text is wrapped to the next line
const DATE_UPDATED_COLUMN_MIN_WIDTH = 100; //Minimum width of date updated column before text is wrapped to the next line

function createLabelFunction(columnName) {
    return ({ sorted, descending }) => {
      const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
      return `${columnName}, ${sortState}.`;
    };
}

/**
 * Modal for contact info of admins
 *
 * @param {visible} visible Control visibility of modal
 * @param {setHistoryVisibility} setHistoryVisibility Control visibility of modal
 * @param {history} history Control visibility of modal
 * @public
 */
export default function HistoryModal(props) {
    const [filteredHistory, setFilteredHistory] = React.useState([]); //User filitered history with search box in table
    const [filteringText, setFilteringText] = React.useState(""); //User-entered search text

    let status = props.visible;

    const handleDismiss = (e) => {
        e.preventDefault();
        props.setHistoryVisibility(false)
    };

    const columnDefinitions = [
        {
            id: "id",
            header: "Issue ID",
            cell: (item) => item.id,
            ariaLabel: createLabelFunction('id'),
            sortingField: 'id',
            minWidth: ID_COLUMN_MIN_WIDTH
        },
        {
            id: "status_id",
            header: "Change ID",
            cell: (item) => item.status_id,
            ariaLabel: createLabelFunction('status_id'),
            sortingField: 'status_id',
            minWidth: ID_COLUMN_MIN_WIDTH
        },
        {
            id: "summary",
            header: "Summary",
            cell: (item) => {
                if(props.subIssueSummaryMapping && props.subIssueSummaryMapping[item.id]) {
                    return props.subIssueSummaryMapping[item.id];
                }
                return '--';
            },
            ariaLabel: createLabelFunction('summary'),
            sortingField: 'summary',
            minWidth: SUMMARY_COLUMN_MIN_WIDTH
        },
        {
            id: "date_updated",
            header: "Timestamp",
            cell: (item) => {
                return item.date_updated;
            },
            minWidth: DATE_UPDATED_COLUMN_MIN_WIDTH,
        },
        {
            id: "justification",
            header: "Status",
            cell: (item) => {
                return item.justification ? item.justification : '--';
            },
            minWidth: STATUS_COLUMN_MIN_WIDTH,
        },
        {
            id: "comment",
            header: "Comments",
            cell: (item) => {
                return item.comment ? item.comment : '--';
            },
            minWidth: COMMENTS_COLUMN_MIN_WIDTH,
        },
        {
            id: "updated_by",
            header: "Updated By",
            cell: (item) => {
                return item.updated_by;
            },
            minWidth: UPDATED_BY_COLUMN_MIN_WIDTH,
        },
        {
            id: "fix_confirmed",
            header: "Fix Confirmed",
            cell: (item) => {
                if(item.fix_confirmed) {
                    return(<Icon name="status-positive" variant="success"/>); //Display success icon for fix confirmed
                }
                return(<Icon name="status-negative" variant="error"/>); //No fix confirmed, display error icon
            },
            minWidth: FIX_CONFIRMED_COLUMN_MIN_WIDTH,
        }
    ];

    const collectionPreferences = { //Column layout and table prefs
        visibleContent: [
            "id",
            "summary",
            "justification",
            "comment",
            "fix_confirmed",
            "updated_by",
            "date_updated",
        ],
        wrapLines: true,
        custom: "table"
    };

    React.useEffect(() => {
        setFilteredHistory(props.history);
    }, [props.history]);

    const { items, collectionProps } = useCollection(
        filteredHistory,        // exact vuln only
        {
            sorting: {},
        }
    );

    return (
        <Modal
            onDismiss={handleDismiss}
            visible={status}
            size="max"
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={handleDismiss}>Close</Button>
                </SpaceBetween>
                </Box>
            }
            header={`History ${props.issue && props.issue['ticket_id'] ? 'for ' + props.issue['ticket_id'] : ''}`}
        >
            {/* <p>Hello World</p> */}
            <Table
                items={items}
                columnDefinitions={columnDefinitions}
                visibleColumns={collectionPreferences.visibleContent}
                contentDensity="compact"
                trackBy="status_id"
                sortingDisabled={true}
                wrapLines={true}
                loading={props.loading}
                loadingText={"Loading History"}
                {...collectionProps}
                filter={
                    <TextFilter
                        filteringPlaceholder="Search"
                        filteringText={filteringText}
                        onChange={({ detail }) => {
                                let filter = detail.filteringText.toLowerCase().trim();

                                if(!filter === null || filter.length === 0) {
                                    setFilteredHistory(props.history);
                                }
                                else {
                                    let tmp = [];
                                    for(const issue of props.history) {
                                        //Filter history on matching id, summary, status, or comment (compared lowercase)
                                        let summary = '';
                                        if(props.subIssueSummaryMapping && props.subIssueSummaryMapping[issue.id]) {
                                            summary = props.subIssueSummaryMapping[issue.id].toLowerCase();
                                        }
                                        let id = issue.id ? issue.id.toLowerCase() : '';
                                        let status = issue.justification ? issue.justification.toLowerCase() : '';
                                        let comment = issue.comment ? issue.comment.toLowerCase() : '';
                                        let updated_by = issue.updated_by ? issue.updated_by.toLowerCase() : '';
                                        
                                        if(id.includes(filter)
                                            || summary.includes(filter)
                                            || status.includes(filter)
                                            || comment.includes(filter)
                                            || updated_by.includes(filter)) {
                                            tmp.push(issue);
                                        }
                                    }
                                    setFilteredHistory(tmp);
                                }
                                
                                setFilteringText(detail.filteringText);
                            }
                        }
                    />
                }
                empty={
                    <div className="awsui-util-t-c">
                        <div className="awsui-util-pt-s awsui-util-mb-xs">
                            <b>No History</b>
                        </div>
                    </div>	
                }
            />
        </Modal>
    );
}