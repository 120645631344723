import React from 'react';
import { useDispatch } from "react-redux";
import * as DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    Button,
    Spinner,
    SpaceBetween,
    Modal,
    FormField,
    Input,
    StatusIndicator,
    Popover,
    Toggle,
    Hotspot
} from '@amzn/awsui-components-react/polaris';
import { setStatusBarMessage } from "./app/actions";
import { formatDescription } from "./DescriptionParser";
import './styles/FormPage.css'
import ExternalLink from "./ExternalLink";
import DateDisplay from './DateDisplay';
import { returnAPIURL } from "./Constants"
import { getJwtToken } from './Auth';
import axios from "axios";
import HistoryButton from "./HistoryButton";

/**
 * Key/Value pair display
 *
 * @param {label} key
 * @param {children} value
 * @protected
 */
const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div style={{whiteSpace: "pre-line"}}>{children}</div>
    </div>
);

function getDate() {
    var currentdate = new Date(); 
    var datetime = currentdate.getFullYear() + "-"
                    + (currentdate.getMonth()+1)  + "-" 
                    + currentdate.getDate() + " "  
                    + currentdate.getHours() + ":"  
                    + currentdate.getMinutes() + ":" 
                    + currentdate.getSeconds();
    
    return datetime;
}

function RequestHelpButton (props) {
    // if loading, show nothing
    if(props.loading) {
         return '';
    }
 
    const handleRequestHelpAction = (e) => {
        e.preventDefault();
        props.callback();
     }
 
    return <Button variant="primary" onClick={handleRequestHelpAction}>Send Request</Button>
};

/**
 *
 * @param {errorState} 
 */
function RequestHelpNoErrorMessage (props) {
    if(props.errorState){
        return(
            <StatusIndicator type="error">
                Please provide a description of what you need help with so we can better assist you.
            </StatusIndicator>
        )
    }
}

function CancelHelpButton (props) {
    if(props.loading) {
        return <Spinner />;
    }
 
    const handleCancelAction = (e) => {
        e.preventDefault();
        props.callback();
     }
 
    return <Button variant="primary" onClick={handleCancelAction}>Cancel Help Request</Button>
};

/**
 * Layout for requesting help via button. If help has already been requested, button is disabled and a tooltip shows current help comment
 * Implementation TBD
 * @param {issue} issue dict from frontend API
 * @param {loading} whether frontend API request is still pending 
 * @param {user} user
 * @protected
 */
function HelpRequestLayout(props) {
    //todo: We need to handle displaying comment and disabling help button when help has already been requested. Requires backend integration
    const [modalVisible, setModalVisible] = React.useState(false);
    const [value, setValue] = React.useState("");
    const dispatch = useDispatch();
    const apiURL = returnAPIURL()
    let help_request = props.issue.help_request
    const [helpRequest, setHelpRequest] = React.useState(help_request)
    const [helpRequestLoading, setHelpRequestLoading] = React.useState(false)
    const [helpRequestLoadingNoValue, setHelpRequestLoadingErrorNoValue] = React.useState(false)

    React.useEffect(() => { setHelpRequest(help_request)}, [help_request] )

    // if props is loading, show spinner
    if(props.loading) {
        return(
            <div align="center">
                <Spinner />
            </div>
        );
    }

    // if there's no issue (invalid id or API error), disable the button
    if(!props.issue || Object.keys(props.issue).length < 1) {
        return <Button disabled variant='primary'>Request Help</Button>
    }

    const handleCloseHelpRequest = (e) => {
        e.preventDefault();
        setModalVisible(false);
        setValue("");
    }

    async function handleCancelHelpRequest(e) {
        // Get auth for axios
        setHelpRequestLoading(true)

        let tok = getJwtToken()

        const options = {
            headers: {
                'Authorization': `Bearer ${tok}`,
                'Content-Type': 'application/json'
            },
        };
    
        try {
            // Get request to get issue contents
            await axios
            .delete(apiURL + "/help?id=" + props.ticket_id, options)
            .then((response) => {
                console.log(response);
                dispatch(setStatusBarMessage({type: 'success', message: 'Help request canceled'}))

                help_request = null

                setHelpRequest(help_request)
            })
            .catch((error) => {
                dispatch(setStatusBarMessage({type: 'error', message: 'An error has occured. The help request could not be canceled.'}))
                if (error.response) {
                    console.log(error.response);
                } else if (error.request) {
                    console.log("network error");
                } else {
                    console.log(error);
                }
            });

        } catch (error) {
            console.log('error has occured')
        }

        setModalVisible(false);
        setHelpRequestLoading(false)
        setValue("");
    }

    async function handleSubmitHelpRequest() {
        // e.preventDefault();
        setHelpRequestLoading(true)

        if(value) {
            // Get auth for axios
            let tok = getJwtToken()

            const options = {
              headers: {
                'Authorization': `Bearer ${tok}`,
                'Content-Type': 'application/json'
              },
            };

            let help_comment = value

            let help_payload = {
                "ticket_uuid": props.ticket_id,
                "help_request": {
                    "comment": help_comment
                }
            }
        
            try {
                // Get request to get issue contents
                await axios
                .post(apiURL + "/help", help_payload, options)
                .then((response) => {
                    console.log(response);
                    dispatch(setStatusBarMessage({type: 'success', message: 'Help requested'}))

                    help_request = {
                        "comment": help_comment,
                        "user": props.user,
                        "date_created": getDate(),
                        "cancel_allowed": true
                    }

                    setHelpRequest(help_request)
                    setModalVisible(false);
                    setHelpRequestLoadingErrorNoValue(false)
                })
                .catch((error) => {
                    dispatch(setStatusBarMessage({type: 'error', message: 'An error has occured. The help request could not be submitted.'}))
                    if (error.response) {
                        console.log(error.response);
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                    }
                });

            } catch (error) {
                console.log('error has occured')
            }
        }
        else{
            setHelpRequestLoadingErrorNoValue(true)
        }

        setHelpRequestLoading(false)
        setValue("");
    }

    if (helpRequest){
        // If a help request already exists
        let comment = helpRequest.comment
        let requester = helpRequest.user
        let date = helpRequest.date_created
        let cancel_allowed_flag = helpRequest.cancel_allowed

        let head = "Requested by " + requester + "@ on " + date

        let cancelHelp
        if (cancel_allowed_flag){
            cancelHelp = <CancelHelpButton loading={helpRequestLoading} callback={handleCancelHelpRequest}/>
        }
        else {
            cancelHelp =  <Box color="text-body-secondary">Please reach out to help requester to cancel the request</Box>
        }

        return(
            <div>
                <Popover
                    dismissButton={false}
                    position="top"
                    size="medium"
                    triggerType="custom"
                    header={head}
                    content={   
                        <SpaceBetween size="xs">
                            <StatusIndicator type="info">
                                {comment}
                            </StatusIndicator>
                            {cancelHelp}        
                        </SpaceBetween>
                    }
                >
                    <Button>Help Requested</Button>
                </Popover>
            </div>
        );
    }
    else {
        // If no help request
        return(
            <div>
                <Button variant='primary' onClick={() => setModalVisible(true)}>Request Help</Button>
                <Modal
                    onDismiss={handleCloseHelpRequest}
                    visible={modalVisible}
                    closeAriaLabel="Close modal"
                    footer={
                        <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={handleCloseHelpRequest}>Cancel</Button>             
                            <RequestHelpButton loading={helpRequestLoading} callback={handleSubmitHelpRequest}/>
                        </SpaceBetween>
                        </Box>
                    }
                    header="Request Help"
                >
                    <FormField
                        label="Please provide a brief description of what you need help with"
                        description="Please note: Only one active help request can be submitted at a time."
                    >
                        <SpaceBetween size='xs'>
                            <RequestHelpNoErrorMessage errorState={helpRequestLoadingNoValue}/>
                            <Input
                                onChange={({ detail }) => setValue(detail.value)}
                                value={value}
                                placeholder="Add comments to your help request"
                            />
                        </SpaceBetween>
                    </FormField>
                </Modal>
            </div>
        );
    }
}

/**
 * ColumnLayout displaying ticket properties, or spinner if still loading
 *
 * @param {issue} issue dict from frontend API
 * @param {loading} whether frontend API request is still pending 
 * @protected
 */
function TicketProperties(props) {
    if(props.loading) {
        return(
            <div align="center">
                <Spinner size="large" />
            </div>
        );
    }

    //Some development and testing issues have source set to "UNKNOWN". In this case, replace with '--'
    let detectionToolName = props.issue && props.issue.test_suite ? props.issue.test_suite : '--';
    detectionToolName = detectionToolName === 'UNKNOWN' ? '--' : detectionToolName;

    //Column layout with issue properties
    return(
        <SpaceBetween size="l">
            <ColumnLayout columns={3} variant="text-grid">
                <ValueWithLabel label="ID"><ExternalLink link={props.issue.ticket_url} text={props.issue.ticket_id}/></ValueWithLabel>
                <ValueWithLabel label="Detection Tool">{detectionToolName}</ValueWithLabel>
                <ValueWithLabel label="Created"><DateDisplay date={props.issue.date_filed}/></ValueWithLabel>
            </ColumnLayout>
            <ValueWithLabel label="Description">
                <Toggle
                    onChange={({detail}) => props.setShowFormattedDescription(detail.checked)}
                    checked={props.showFormattedDescription}
                    disabled={!props.issue || Object.keys(props.issue).length < 1}
                >
                    <small>Formatted Description</small>
                </Toggle>
                {parse(DOMPurify.sanitize(props.issue && props.issue.description ? formatDescription(props.issue.description, props.showFormattedDescription) : '--'))}
            </ValueWithLabel>
        </SpaceBetween>
    );
}

/**
 * Exported panel with issue properties including ID, detection tool, filing date, and description
 *
 * @param {issue} issue dict from frontend API
 * @param {loading} whether frontend API request is still pending 
 * @param {ticket_id} ticket_id
 * @param {user} user
 * @param {history} history
 * @public
 */
export default function IssuePropertiesPanel(props) {
    //Container displaying basic ticket properties and allowing the user to request help via modal
    const [showFormattedDescription, setShowFormattedDescription] = React.useState(true);

    return (
        <Container 
            header={
                <Header 
                    variant="h2"
                    actions={
                        <SpaceBetween size='xxs' direction='horizontal'>
                            <HistoryButton history={props.history} subIssueSummaryMapping={props.subIssueSummaryMapping} issue={props.issue} loading={props.loadingHistory}/>
                            <HelpRequestLayout issue={props.issue} loading={props.loading} user={props.user} ticket_id={props.ticket_id}/>
                            <Hotspot hotspotId="issue_properties_hotspot" direction="right"/>
                        </SpaceBetween>
                    }
                >
                    Ticket Info
                </Header>
            }
        >
            <TicketProperties 
                issue={props.issue} 
                loading={props.loading} 
                showFormattedDescription={showFormattedDescription} 
                setShowFormattedDescription={setShowFormattedDescription} 
            />
        </Container>
    );
}