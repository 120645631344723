import * as React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import ExternalLink from "./ExternalLink";


/**
 * Modal for contact info of admins
 *
 * @param {visible} visible Control visibility of modal
 * @param {setHelpVisibility} setHelpVisibility Control visibility of modal
 * @public
 */
export default function DropdownModalHelp(props) {

    let status = props.visible

    const handleDismiss = (e) => {
        e.preventDefault();
        props.setHelpVisibility(false)
    }

    return (
    <Modal
        onDismiss={handleDismiss}
        visible={status}
        closeAriaLabel="Close modal"
        footer={
            <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={handleDismiss}>Close</Button>
            </SpaceBetween>
            </Box>
        }
      header="Help"
    >
        <span>
            <p>
                Welcome to Issue Companion! Please use this page to set ticket resolution, sub-issue statuses, or request help.
            </p>
            <p>
                The left side of the screen has the ticket description and the right side has the ticket interaction window where you can mark issues as resolved.
            </p>
            <p>
                To update an issue as <i>resolved</i>, set the Status and Comment fields in the row and click Save. 
                To update multiple sub-issues as <i>resolved</i>, use the checkboxes in the left most column and do a bulk edit. At the bottom of the table, set the status and comment for the bulk edit, and click Save.
            </p>
            <p>
                If you still need assistance filling out the form, please cut a ticket here.
                <ExternalLink text=" Contact Us" link="https://tiny.amazon.com/v3iy6lor"/>
            </p>
        </span>
    </Modal>
  );
}