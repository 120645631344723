import React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import store from './app/store';
import { Provider } from 'react-redux';
import App from './App'
import * as serviceWorker from './serviceWorker';
import { readEnvVars } from './app/envVarsReader';

readEnvVars().then(() => {
  const rootElement = document.getElementById('root');
  const root = createRoot(rootElement);
  root.render(
    <StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </StrictMode>,
  );
});
serviceWorker.unregister();