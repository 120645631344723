import * as React from "react";
import Popover from "@amzn/awsui-components-react/polaris/popover";

const JS_DATE_TIME_FMT_PATTERN = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3}Z)*/; //Expected Javascript date time format
const JS_DATE_TIME_FMT_SPACE_PATTERN = /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}(\.\d{3}Z)*/; //Date format with ' ' instead of 'T'. Typical of filed issues
const MS_IN_DAY = 1000*60*60*24; //Number of milliseconds in a day

/**
 * Exported date view for popover displaying delta in days between now and provided date
 *
 * @param {date} date string
 * @public
 */
export default function DateDisplay(props) {
    if(!props.date || props.date.length < 1) {
        return '--';
    }

    //Attempts to parse date string. Returns date object or null on error
    const parseDateString = (date_string) => {
        let parsedDateObj = null;

        try { //Attempt to parse date string
            if(JS_DATE_TIME_FMT_PATTERN.test(date_string)) { //Already in Javascript date time string format
                parsedDateObj = Date.parse(date_string); //in ms
            }
            else if(JS_DATE_TIME_FMT_SPACE_PATTERN.test(date_string)) { //Space between date and time. Replace with 'T'
                parsedDateObj = Date.parse(date_string.trim().replace(' ', 'T')); //in ms
            }
            //todo: If other formats are needed, define regex and parse below...
        }
        catch(err) {}
        return parsedDateObj;
    }

    //Parses date string and calculates delta between now and filed date. Returns string.
    const calculateDelta = (date) => {
        if(date !== null) {
            try {
                let now = new Date();

                //Find difference in ms
                let difference_ms = now.getTime() - parsedDateObj;
                let delta_days = Math.round(difference_ms / MS_IN_DAY); 

                if(delta_days < 0) { //Date in the future. Not going to happen, but nice to account for
                    return `${delta_days * -1} days from now`;
                }
                else if(delta_days < 1) { //Between 0 and 1, means this issue was filed today
                    return 'Today';
                }
                else { //Otherwise, filed sometime in the past
                    return delta_days === 1 ? 'Yesterday' : `${delta_days} days ago` 
                }
            }
            catch(err) {}
        }

        return '--'; //Error fallback
    }

    let parsedDateObj = parseDateString(props.date);

    //If possible, display date without timestamp in en-US locale
    return(
        <Popover
            dismissButton={false}
            position="bottom"
            size="small"
            triggerType="text"
            content={calculateDelta(parsedDateObj)}
        >
            {parsedDateObj !== null ? new Date(parsedDateObj).toLocaleDateString('en-US') : props.date}
        </Popover>
    );
}