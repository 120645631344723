import { SET_LOGGED_IN_USER, SET_STATUS_BAR_MESSAGE, SET_THEME, SET_TITLE } from "./actionTypes";

export const setLoggedInUser = id => ({
    type: SET_LOGGED_IN_USER,
    payload: id
});

export const setStatusBarMessage = statusBarMessage => ({
    type: SET_STATUS_BAR_MESSAGE,
    payload: statusBarMessage
});

export const setTheme = theme => ({
    type: SET_THEME,
    payload: theme
});

export const setTitle = title => ({
    type: SET_TITLE,
    payload: title
});