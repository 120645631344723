import * as React from "react";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import { setTheme } from "./app/actions";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import DropdownModalHelp from "./DropdownModalHelp";
import DropdownModalFeedback from "./DropdownModalFeedback";

export default function Dropdown(props) {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.currentViews.theme); //Get current theme
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(['issuevault']); //Set up cookie store

  const [HelpVisible, setHelpVisible] = React.useState(false);
  const [FeedbackVisible, setFeedbackVisible] = React.useState(false);

  const setHelpVisibility = (visibility) => {
    setHelpVisible(visibility);
  } 

  const setFeedbackVisibility = (visibility) => {
    setFeedbackVisible(visibility);
  } 

  const handleDropDownClick = (e) => {
    e.preventDefault();
    let id = e.detail.id

    switch(id){
      case 'Tutorial':
        props.setTutorialVisibleCallback(true);
        break;
      case 'Feedback':
        setFeedbackVisible(true);
        break;
      case 'Help':
        setHelpVisible(true);
        break;
      case 'ToggleTheme':
        let toggle = theme === 'dark' ? 'light' : 'dark';
        dispatch(setTheme(toggle)); //Toogle state
        setCookie('theme', toggle, {path: '/'}); //Set cookie for persistence
        break;
      default:
        console.log("Case not recognized. Recieved ID: " + id);
    }
  }

  return (
    <div>
      <ButtonDropdown
        items={[
          {
            text: "Help and Feedback",
            items: [
              { text: "Show Tutorial", id: "Tutorial", disabled: false },
              { text: "Help", id: "Help", disabled: false },
              { text: "Provide Feedback or Contact Us", id: "Feedback", disabled: false },
            ]
          },
          {
            text: "Settings",
            items: [
              { text: theme === 'dark' ? 'Enable Light Mode' : 'Enable Dark Mode', id: "ToggleTheme" }
            ]
          }
        ]}
        onItemClick={handleDropDownClick}
        variant="icon"
      />
      <DropdownModalHelp visible={HelpVisible} setHelpVisibility={setHelpVisibility}/>
      <DropdownModalFeedback visible={FeedbackVisible} setFeedbackVisibility={setFeedbackVisibility}/>
    </div>

  );
}